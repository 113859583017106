import React from 'react';
import { Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    createBox,
    tableGrid,
    tableWrapper,
    grid,
    loader,
    loading,
} from './client-sms-template-listing.module.scss';
import { IClientSMSTemplate } from '../../models/client-sms-template.model';
import { ISection } from '../../models/section.model';
import { TLocale } from '../../locale';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import { useModal } from '../../hooks/use-modal';
import { useClientSMSTemplate } from '../../hooks/use-client-sms-template';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Table, { ITableProps } from '../organisms/table';
import Button from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import IconFactory from '../hoc/icon-factory';
import HandleFormikChange from '../hoc/handle-formik-change';
import AlertInfo from '../molecules/alert-info';

interface IClientSmsTemplateListingProps {
    section: ISection;
}

interface IClientSmsTemplateListingPropsQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
}

const ClientSmsTemplateListing: React.FC<IClientSmsTemplateListingProps> = ({ section }) => {
    const { style, css, sectionId } = section;
    const { addModal } = useModal();
    const t = useTranslations('ClientSmsTemplateListing');
    const client = useClient();
    const template = useClientSMSTemplate();
    const { allPage } = useStaticQuery<IClientSmsTemplateListingPropsQueryResult>(query);
    const detailsPage = usePagePathname(allPage, 'client-sms-template-preview');
    const advertisementCreatePage = usePagePathname(allPage, 'client-sms-template-form');

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        filters,
        handleChange,
        values,
        pagination,
        isEmpty,
        refetchList,
    } = useList<IClientSMSTemplate>({
        endpoint: `/client-sms-templates`,
        token: client.data?.token.token,
        perPage: 6,
    });

    const isLoading = template.delete.isLoading;

    const handleTemplateDelete = (id: string | number) => {
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleTemplateDeleteConfirmation(id) },
        });
    };

    const handleTemplateDeleteConfirmation = (id: string | number) => {
        return async () => {
            try {
                await template.delete.fetch(id).unwrap();
                refetchList();
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <div className={`${tableWrapper} ${isLoading ? loading : ''}`}>
                    <div className={createBox}>
                        <Button as="link" to={advertisementCreatePage}>
                            {t.newTemplate}
                        </Button>
                        <Tooltip>{t.hint}</Tooltip>
                    </div>
                    {isEmpty && <AlertInfo>{t.empty}</AlertInfo>}
                    {!isEmpty && (
                        <Formik
                            onSubmit={() => {}}
                            initialValues={values || {}}
                            enableReinitialize={true}
                        >
                            {() => (
                                <>
                                    <HandleFormikChange onChange={handleChange} />
                                    <Table
                                        tableClassName={tableGrid}
                                        headerCells={getHeaderCells(t)}
                                        rows={getTemplateRows(
                                            t,
                                            items,
                                            handleTemplateDelete,
                                            detailsPage,
                                            advertisementCreatePage
                                        )}
                                        status={status}
                                        totalCount={pagination?.totalCount || 0}
                                        paginationPaths={paginationPaths}
                                        filters={filters}
                                    />
                                </>
                            )}
                        </Formik>
                    )}
                </div>
            )}
        </Section>
    );
};

function getHeaderCells(t: TLocale['ClientSmsTemplateListing']): ITableProps['headerCells'] {
    return [
        { label: t.id },
        { label: t.name },
        { label: t.createdAt },
        { label: t.author },
        { label: '' },
    ];
}

function getTemplateRows(
    t: TLocale['ClientSmsTemplateListing'],
    templates: IClientSMSTemplate[],
    handleTemplateDelete: (id: string) => void,
    detailsPage: string,
    templateCreatePage: string
): ITableProps['rows'] {
    return templates.map((template) => {
        return [
            {
                type: 'data',
                label: t.id,
                value: template.templateId,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.name,
                value: template.name,
            },
            {
                type: 'data',
                label: t.createdAt,
                value: getDateFromUnixTimestamp(template.createdAt, 'dash'),
            },
            {
                type: 'data',
                label: t.author,
                value: template.author,
            },
            {
                type: 'action',
                actions: [
                    {
                        type: 'button',
                        stylePreset: 'danger',
                        size: 'medium',
                        shape: 'circle',
                        children: <IconFactory icon="trash" />,
                        onClick: () => {
                            handleTemplateDelete(template.templateId.toString());
                        },
                    },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        shape: 'circle',
                        children: <IconFactory icon="edit" />,
                        to: `${templateCreatePage}?id=${template.templateId}&mode=edit`,
                    },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        to: `${detailsPage}?id=${template.templateId}`,
                        children: t.details,
                    },
                ],
            },
        ];
    });
}

export const query = graphql`
    query {
        allPage(
            filter: { type: { in: ["client-sms-template-preview", "client-sms-template-form"] } }
        ) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientSmsTemplateListing;
